import React from 'react'
import ReactDOM from 'react-dom'
import {Button} from 'react-bootstrap'

class VigilusModalButton extends React.Component {
    openModal(){
        window.renderVigilusModal(this.props.type, true, this.props.model, 'vigilus-modal');
    }
    render(){
        let buttonText = this.props.type;
        switch(this.props.type){
            case 'Events':
                buttonText = <i className="fa fa-eye"></i>;
                break;
            case 'Device Model Query':
                buttonText = "Query";
                break;
            case 'UCCE Status':
                buttonText = "UCCE Status";
                break;
        }
        return(
            <div>
                <Button
                    bsStyle="info"
                    bsSize="small"
                    onClick={this.openModal.bind(this)}
                >
                    {buttonText}
                </Button>
            </div>
        )
    }
}

window.renderVigilusModalButton = function(settingsObj){

  // settingsObj expects:
  //          type: selects body component to render in body of modal
  //         model: JSON data model for this.props.model
  //   containerID: ID for element into which button will be rendered

    let container = document.getElementById(settingsObj.containerId);
    ReactDOM.render(<VigilusModalButton type={settingsObj.type} model={settingsObj.model} />, container);
};
